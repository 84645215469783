<template>
  <div class="app_con">
    <div v-if="!isAnalysis">
      <div class="app-container">
        <div class="list">
          <div class="m-b-20">
            <el-row>
              <el-col
                :sm="searchControl.secondSoltSpan[0]"
                :md="searchControl.secondSoltSpan[1]"
                :lg="searchControl.secondSoltSpan[2]"
                :xl="searchControl.secondSoltSpan[3]"
              >
                <div>
                  <el-button
                    type="primary"
                    icon="el-icon-upload2"
                    @click="exportExcel"
                    >导出</el-button
                  >
                  <el-button
                    type="success"
                    icon="el-icon-search"
                    @click="showAnalysis"
                    >分析</el-button
                  >
                </div>
                <!-- <slot name="toolSub" :selecTionArr="selecTionArr"></slot> -->
              </el-col>
              <el-col
                :sm="searchControl.thirdSoltSpan[0]"
                :md="searchControl.thirdSoltSpan[1]"
                :lg="searchControl.thirdSoltSpan[2]"
                :xl="searchControl.thirdSoltSpan[3]"
              >
                <div class="soltWrap">
                  <div class="search">
                    <el-select
                      style="margin-right: 20px"
                      v-model="searchQuery.dateType"
                      @change="setSelect"
                    >
                      <el-option
                        :label="'按日统计'"
                        :value="'date'"
                      ></el-option>
                      <el-option
                        :label="'按月统计'"
                        :value="'month'"
                      ></el-option>
                      <el-option
                        :label="'按年统计'"
                        :value="'year'"
                      ></el-option>
                    </el-select>

                    <span class="lableName">开始{{ tableLabel }}</span
                    ><el-date-picker
                      style="margin-right: 10px; width: 200px"
                      v-model="searchQuery.startDate"
                      :type="searchQuery.dateType"
                      :placeholder="`开始${tableLabel}`"
                      :value-format="dateFormate"
                    >
                    </el-date-picker>

                    <span class="lableName">结束{{ tableLabel }}</span
                    ><el-date-picker
                      style="margin-right: 10px; width: 200px"
                      v-model="searchQuery.endDate"
                      :type="searchQuery.dateType"
                      :placeholder="`开始${tableLabel}`"
                      :value-format="dateFormate"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <el-table
            ref="table"
            :data="listData"
            border
            show-summary
            highlight-current-row
            row-key="id"
          >
            <el-table-column
              prop="dateTime"
              :label="tableLabel"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="clinchNumber"
              label="成交笔数"
            ></el-table-column>
            <el-table-column
              prop="commodityWeight"
              label="商品重量"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="transactionAmount"
              label="交易金额"
              width="180"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-else class="app-container">
      <div class="returnList">
        <el-button type="primary" @click="isAnalysis = false"
          >返回列表</el-button
        >
      </div>
      <div class="echart">
        <echart-line :chartData="chartData"></echart-line>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import echartLine from "./components/line.vue";
export default {
  components: {
    echartLine,
  },
  data() {
    return {
      // searchQuery: {
      //   dateType: "date",
      //   endDate: "",
      //   startDate: "",
      // },
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [5, 5, 5, 5],
        thirdSoltSpan: [19, 19, 19, 19],
      },
      isAnalysis: false,
      listData: [],
      rangeType: "daterange",
      daterange: "",
      searchQuery: {
        dateType: "date",
        endDate: "",
        startDate: "",
        currentPage: 1,
        pageSize: 15,
        apiName: "/statistics/transactionAnalysis",
      },
      chartData: {
        xAxis: ["张三", "李大钊", "陈独秀", "李四", "苏大强"],
        series: [
          {
            title: "交易笔数",
            value: [12, 32, 10, 14, 19, 23, 21],
          },
          {
            title: "商品重量",
            value: [12.0, 13.2, 10.1, 13.4, 9.0, 23.0, 21.0],
          },
          {
            title: "交易金额",
            value: [120, 132, 101, 134, 90, 230, 210],
          },
        ],
      },
    };
  },
  filters: {
    StatusFilter(val) {
      const statusMap = { 0: "合同期内", 1: "合同过期" };
      return statusMap[val];
    },
    payStatusFilter(val) {
      const statusMap = { 0: "未缴费", 1: "已缴费" };
      return statusMap[val];
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.getDataList();
      },
      deep: true, //开启深度监听
    },
  },
  created() {
    this.getDataList();
  },
  computed: {
    tableLabel() {
      let data = this.searchQuery.dateType;
      let str = "";
      switch (data) {
        case "date":
          str = "日期";
          break;
        case "month":
          str = "月份";
          break;
        case "year":
          str = "年份";
          break;
        default:
          str = "日期";
      }
      return str;
    },
    dateFormate() {
      let dateType = this.searchQuery.dateType;
      let str = "";
      switch (dateType) {
        case "date":
          str = "yyyy-MM-dd";
          break;
        case "month":
          str = "yyyy-MM";
          break;
        case "year":
          str = "yyyy";
          break;
        default:
          str = "yyyy-MM-dd";
      }
      return str;
    },
  },
  methods: {
    async getDataList() {
      await api.common.list(this.searchQuery).then((res) => {
        this.listData = res.data.data;
        console.log(this.listData);
      });
    },

    setSelect(e) {
      this.searchQuery.dateType = e;
      this.searchQuery.startDate = "";
      this.searchQuery.endDate = "";
    },
    getDaterange(e) {
      console.log(e);
    },

    setPayItem(e, index) {
      let stallObj = this.stallList.find((item) => item.id == e);

      this.form.merchantContractStall[index].rentMoney = stallObj.stallRent;
    },
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/user/exportExcel",
        ...searchQuery,
      });
    },
    showAnalysis() {
      let data = this.listData;
      this.chartData.xAxis = data.map((x) => {
        return x.dateTime;
      });
      this.chartData.series[0].value = data.map((x) => {
        return x.clinchNumber;
      });
      this.chartData.series[1].value = data.map((x) => {
        return x.commodityWeight;
      });
      this.chartData.series[2].value = data.map((x) => {
        return x.transactionAmount;
      });
      this.isAnalysis = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.lableName {
  line-height: 40px;
  background: #f5f7fa;
  color: #909399;
  height: 40px;
  border: 1px solid #dcdfe6;
  padding: 0 8px;
  border-right: none;
  left: 2px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 14px;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
.toolWrap {
  margin-bottom: 20px;
}
.formdate {
  width: 220px;
}
.partInput {
  width: 210px;
  margin-right: 10px;
}
.radioWidth {
  width: 240px;
}
</style>

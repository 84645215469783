b<template>
  <div class="con_wrap">
    <div :id="idName" :style="{ height: height, width: width }" />
  </div>
</template>

<script>
// import api from "@/api";
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
export default {
  props: {
    idName: {
      type: String,
      default: "commodity",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
    pieData: { Object, default: Array },
    chartData: { Object, default: Array },
  },
  data() {
    return {
      chart: null,
    };
  },

  watch: {
    chartData: {
      handler(val) {
        if (!this.$utils.isEmpty(val)) {
          this.initChart();
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    // 初始化图表
    initChart() {
      var chartDom = document.getElementById(this.idName);
      this.chart = echarts.init(chartDom, "macarons");
      this.setOptions();
    },
    // 配置数据
    setOptions() {
      console.log(this.chartData);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        // legend: {
        //   data: ["在场人数"],
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chartData.xAxis.map((x) => {
            return x;
          }),
        },
        yAxis: {
          type: "value",
        },
        series: this.chartData.series.map((x) => {
          return {
            name: x.title,
            type: "line",
            data: x.value,
            smooth: false,
            symbolSize: 10,
          };
        }),
        // [

        //   {
        //     name: "人数",
        //     type: "line",
        //     data: this.chartData.map((x) => {
        //       return x.number;
        //     }),
        //     smooth: false,
        //     symbolSize: 10,
        //   },
        // ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>
